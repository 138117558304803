<template>
	<div id="Comment">
		<div class="commentbox">
			<div class="commentbox-tab">
				<div class="cursor" :class="typeIndex == index ? 'commentbox-tab-a' : 'commentbox-tab-i'"
					v-for="(item,index) in tabList" :key="index" @click="toggleClick(index)">
					{{item}}
				</div>
			</div>
			<div class="salebox-list" v-for="(item,index) in orderArr" :key="index">
				<div class="salebox-list-t flc">
					<div class="salebox-list-t-l">
						{{item.updated.slice(0,10)}}
					</div>
					<div class="salebox-list-t-r">
						订单编号：{{item.number}}
					</div>
					<!-- 客服 -->
					<!-- <img src="../../assets/img/kefu.png" style="width: 20px;height: 20px;margin-left: 60px;" @click="clickkefu" > -->
				</div>
				<div class="salebox-list-b flc">
					<div class="salebox-list-b-l flc">
						<div class="salebox-list-b-l-box">
							<div class="salebox-list-b-l-box-i flc" v-for="(item2,index2) in item.orderItemResponses"
								:key="index2">
								<el-image class="salebox-list-b-l-i" :src="item2.cover" fit="cover">
								</el-image>
								<div class="salebox-list-b-l-l">
									<div class="salebox-list-b-l-l-t line-two">
										{{item2.title}}
									</div>
									<div class="salebox-list-b-l-l-b flc">
										<div class="salebox-list-b-l-l-b-l">
											¥{{item2.price}}
										</div>
										<div class="salebox-list-b-l-l-b-r">
											×{{item2.num}}
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="salebox-list-b-l-r ">
							<div class="salebox-list-b-l-r-t">
								¥{{item.total}}
							</div>
							<div class="salebox-list-b-l-r-b">
								（含配送费¥{{item.postage}}）
							</div>
						</div>
					</div>

					<div class="salebox-list-b-c">
						<div class="salebox-list-b-c-t">
							{{statedList[item.stated]}}
						</div>
						<!-- <div class="salebox-list-b-c-b">
							订单详情
						</div> -->
					</div>
					<router-link class="salebox-list-b-b cursor" :to="{name:'comment_submit',query:{cc:item.oid,cid:item.orderItemResponses[0].orderItemId}}"
						v-if="item.stated == 3">
						<!-- <div class="salebox-list-b-b-t">
							评价
						</div>
						<div class="salebox-list-b-b-b">
							申请售后
						</div> -->
						评价
					</router-link>
				</div>
			</div>

			<el-pagination class="salebox-pagination" background layout="prev, pager, next" :total="total"
				@current-change="handleCurrentChange">
			</el-pagination>
			
			
		</div>
		
		<kefu v-if="shows"></kefu>
	</div>
</template>

<script>
	import {
		orderlist
	} from "../../utils/index.js"
	import kefu from "../common/kefu.vue"

	export default {
		name: "Comment",
		components:{
			kefu
		},
		data() {
			return {
				tabList: ["待评价", "已评价"],
				typeIndex: 0,
				formData: {
					page: 1,
					size: 10,
					stated: 3
				},
				total: 0,
				orderArr: [],
				statedList: ["待付款", "待发货", "配送中", "待评论", "已完成", "退款"],
				shows:false
			}
		},
		created() {
			this.orderlist()
		},
		methods: {
			// 订单列表
			orderlist() {
				// 0待支付1代发货2配送中3带评论4已完成5退款
				orderlist(this.formData).then(res => {
					//console.log("订单列表", res);
					this.orderArr = res.data.records
					this.total = res.data.total * 1
					this.$forceUpdate()
				})
			},
			handleCurrentChange(val) {
				//console.log(`当前页: ${val}`);
				this.formData.page = val
				this.orderlist()
			},
			toggleClick(index) {
				this.typeIndex = index
				if (this.typeIndex == 0) {
					this.formData.stated = 3
				} else {
					this.formData.stated = 4
				}

				this.formData.page = 1
				this.orderlist()
			},
			clickkefu(){
				this.shows=!this.shows
			}
		}
	}
</script>

<style>
</style>
