<template>
	<div id="Centre">
		<div class="centrebox">
			<div class="centrebox-b flc">
				<el-image class="centrebox-b-pic" v-if="userInfo.headImg" :src="userInfo.headImg" :fit="'cover'">
				</el-image>
				<div class="centrebox-b-c">
					<div class="centrebox-b-c-t flc">
						<div class="centrebox-b-c-t-l">
							{{userInfo.nickname}}
						</div>
						<div class="centrebox-b-c-t-r">
							积分:{{userInfo.integral}}积分
						</div>
					</div>
					<div class="centrebox-b-c-c">
						绑定手机号：{{userInfo.phone}}
					</div>
					<div class="centrebox-b-c-b" v-if="this.aid==''" @click="isPop = true">
						收货地址
					</div>
					<div class="centrebox-b-c-b" v-else @click="isPop = true">
						{{morenaid}}
					</div>
				</div>
				<div class="centrebox-b-r">
					基本信息
				</div>
			</div>
		</div>

		<el-dialog title="收获信息" :visible.sync="isPop" width="800px" :before-close="handleClose">
			<div class="addinfo">
				<div class="addinfo-line">

				</div>
				<div class="addinfo-box-i">
					<div class="addinfo-i cursor" v-for="(item,index) in addressList" :key="index"
						@click.stop="chooseAddress(index,item.id)">
						<!-- <img class="addinfo-i-l"
							:src="addIndex === index ? require('../../assets/img/discounts5.png') : require('../../assets/img/discounts4.png') "> -->
						<div class="addinfo-i-c">
							{{item.address}}({{item.username}} {{item.phone}})
						</div>
						<div class="addinfo-i-r cursor" @click.stop="compileAdd(index)">
							编辑
						</div>
					</div>
				</div>
				<div class="addinfo-add cursor" @click="addPopClick">
					新增地址
				</div>
			</div>
		</el-dialog>

		<addAddress :isaddPop="isaddPop" @addSuccess="addSuccess" :addDetailone="addDetailone"
			@handleCloseAddPop="handleCloseAddPop"></addAddress>

	</div>
</template>

<script>
	import {
		consumerinfo,
		consumeraddressList
	} from "../../utils/index.js"

	import addAddress from "../common/add_address.vue"

	export default {
		name: "Centre",
		components: {
			addAddress
		},
		data() {
			return {
				userInfo: {},
				addressList: [],
				isPop: false,
				isaddPop: false,
				addDetailone: {},
				aid:'',
				morenaid:''
			}
		},
		created() {
			this.consumerinfo()
			
		},
		watch: {
			isaddPop(val) {
				if (!val) {
					this.addDetailone = {}
				}
			},
		},
		methods: {
			// 用户详情
			consumerinfo() {
				consumerinfo().then(res => {
					 //console.log("用户详情", res);
					this.userInfo = res.data
					this.aid=res.data.aid
					this.consumeraddressList()
					//console.log(this.aid)
				})
			},
			// 用户地址列表
			consumeraddressList() {
				consumeraddressList().then(res => {
					 //console.log("用户地址列表", res);
					this.addressList = res.data
					 res.data.forEach((e)=>{
						 if(e.id===this.aid){
							 this.morenaid= e.address+"("+e.username+e.phone+")"
						 }
					 })
				})
			},
			addPopClick() {
				this.isaddPop = true
			},
			addSuccess() {
				this.consumeraddressList()
				this.isaddPop = false
			},
			compileAdd(index) {
				this.isaddPop = true
				this.addDetailone = this.addressList[index]
			},
			handleCloseAddPop() {
				this.isaddPop = false
			},
			handleClose(done) {
				done();
			}
		}
	}
</script>

<style>
</style>
