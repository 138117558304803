<template>
	<div id="Userinfo">
		<Navigation />
		<ClassifyChoose></ClassifyChoose>
		<SideNavigation></SideNavigation>
		<Breadcrumb :navigationList="navigationList"></Breadcrumb>

		<div class="userinfo-box">
			<!-- 左 -->
			<div class="userinfo-box-l">
				<div class="cursor" :class="tabIndex == index ? 'userinfo-box-l-a' : 'userinfo-box-l-i'"
					v-for="(item,index) in tabList" :key="index" @click="tabIndex = index">
					{{item}}
				</div>
			</div>

			<!-- 右 -->
			<div class="userinfo-box-r">
				<Centre v-if="tabIndex == 0"></Centre>
				<!-- <afterSale v-if="tabIndex == 1"></afterSale> -->
				<Order v-if="tabIndex == 1"></Order>
				<Collect v-if="tabIndex == 2"></Collect>
				<Comment v-if="tabIndex == 3"></Comment>
				<Footprint v-if="tabIndex == 4"></Footprint>
				<Discount v-if="tabIndex == 5"></Discount>
				<News v-if="tabIndex == 6"></News>
        <Feedbacklist  v-if="tabIndex == 7" ></Feedbacklist>
			</div>
		</div>

		<Footer></Footer>
	</div>
</template>

<script>
	import Navigation from "../common/navigation.vue"
	import ClassifyChoose from "../common/classify_choose.vue"
	import Breadcrumb from "../common/breadcrumb.vue"
	import SideNavigation from "../common/side_navigation.vue"
	import Centre from "./centre.vue"
//	import afterSale from "./after_sale.vue"
	import Order from "./order.vue"
	import Collect from "./collect.vue"
	import Comment from "./comment.vue"
	import Footprint from "./footprint.vue"
	import Discount from "./discount.vue"
	import Footer from "../common/footer.vue"
	import News from "./news.vue"
	import Feedbacklist from "./Feedbacklist.vue"
	
	
	export default {
		name: "Userinfo",
		components: {
			Navigation,
			ClassifyChoose,
			Breadcrumb,
			SideNavigation,
			Centre,
			//afterSale,
			Order,
			Collect,
			Comment,
			Footprint,
			Discount,
			Footer,
			News,
      Feedbacklist,
		},
		data() {
			return {
				navigationList: [{
					name: "首页",
					url: "/",
					type: 0
				}, {
					name: "用户中心",
					url: "",
					type: 2
				}],
				tabList: ["用户中心", "我的订单", "我的收藏", "我的评价", "我的足迹", "我的优惠券","消息中心","反馈信息"],
				tabIndex: 1,

			}
		},
		created() {
			if (this.$route.query.type) {
				this.tabIndex = this.$route.query.type
			}
			//console.log(this.tabIndex )
		},
		watch: {
			$route: {
				handler(newobj) {
					this.tabIndex = newobj.query.type
				}
			}
		},
	}
</script>

<style lang="less">
	@import url("userinfo.less");
</style>
