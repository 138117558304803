<template>
	<div id="Discount">
		<div class="discountbox">
			<div class="discountbox-t">
				<div class="discountbox-t-text">
					优惠券
				</div>
			</div>
			<div style="display: flex;margin-top: 20px; cursor: pointer;">
				<div :class="shows==index?'text1':'text2'"
				 v-for="(item,index) in toplist" :key="index"@click="showw(index)">
					{{item}}
				</div>
			</div>
			<!-- 已过期 -->
			<div class="discountbox-list" v-if="shows==1">  
				<div class="discountbox-list-i" v-for="(item,index) in list1" :key="index">
					<img class="discountbox-list-i-a" src="../../assets/img/discount1.png">
					<div class="discountbox-list-i-b">
						优惠券
					</div>
					<div class="discountbox-list-i-c">
						￥ <span class="discountbox-list-i-c-l">{{item.minPrice}}</span> 元
					</div>
					<div class="discountbox-list-i-d">
						{{item.title}}
					</div>
					<div class="discountbox-list-i-e">
						有效期{{item.startTime.slice(0,10)}}-{{item.endTime.slice(0,10)}}
					</div>

					<img class="discountbox-list-i-f" src="../../assets/img/discount2.png">
					<div class="discountbox-list-i-g">
						已过期
					</div>
				</div>
			</div>
			<!-- 未使用 -->
			<div class="discountbox-list" v-else>
				<div class="discountbox-list-i" v-for="(item,index) in list2" :key="index">
					<img class="discountbox-list-i-a" src="../../assets/img/discount1.png">
					<div class="discountbox-list-i-b">
						优惠券
					</div>
					<div class="discountbox-list-i-c">
						￥ <span class="discountbox-list-i-c-l">{{item.minPrice}}</span> 元
					</div>
					<div class="discountbox-list-i-d">
						{{item.title}}
					</div>
					<div class="discountbox-list-i-e">
						有效期{{item.startTime.slice(0,10)}}-{{item.endTime.slice(0,10)}}
					</div>
			
					<img class="discountbox-list-i-f" src="../../assets/img/discount2.png">
					<div class="discountbox-list-i-g">
						立即使用
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		consumercouponList
	} from "../../utils/index.js"

	export default {
		name: "Discount",
		data() {
			return {
				toplist:['未使用',"已过期"],
				couponList: [],
				list1:[],
				list2:[],
				shows:0,
			}
		},
		created() {
			this.consumercouponList()
		},
		methods: {
			showw(e){
				if(e===0){
					this.shows=0
				}else{
					this.shows=1
				}
			},
			// 用户优惠券列表
			consumercouponList() {
				consumercouponList().then(res => {
					//console.log("用户优惠券列表", res);
					this.couponList = res.data
					this.couponList.forEach((e)=>{
						let now = new Date() //当前时间
						//如果传2022-02-22 14:15:00要转化成 2022/02/22 14:15:00
						let startTime = e.endTime
						let end = new Date(startTime.replace(/-/g,"/")) //对比时间
						if(now.getTime() > end.getTime()){
						    //当前时间比指定时间大  已过期
							//console.log("大")
							this.list1.push(e)
						}else{
						    //小于指定时间 未过期
							//console.log("小")
							this.list2.push(e)
						}
					})
				})
			}
		}
	}
</script>

<style >
	.text1{
		font-size: 21px;
		color: #D80000;
		margin-right: 30px;
	}
	.text2{
		font-size: 21px;
		color: #000;
		margin-right: 30px;
	}
</style>
