<template>
	<div id="Footprint">
		<div class="collectbox">
			<div class="collectbox-i" v-for="(item,index) in historyList" :key="index" @click="dianji(item.id)" v-if="item">
				<el-image class="collectbox-i-t" :src="item.cover" fit="cover"></el-image>
				<div class="collectbox-i-c line-two">
					{{item.title}}
				</div>
				<div class="collectbox-i-b">
					¥{{item.price}}
				</div>
			</div>
		</div>

		<el-pagination class="salebox-pagination" background layout="prev, pager, next" :total="total"
			@current-change="handleCurrentChange">
		</el-pagination>
	</div>
</template>

<script>
	import {
		goodshistory
	} from "../../utils/index.js"

	export default {
		name: "Footprint",
		data() {
			return {
				formData: {
					page: 1,
					size: 12
				},
				total: 0,
				historyList: []
			}
		},
		created() {
			this.goodshistory()
		},
		methods: {
			dianji(id){
				this.$router.push({
					path:'shop_detail',
					query:{
						id:id
					}
				})
			},
			// 浏览记录
			goodshistory() {
				goodshistory(this.formData).then(res => {
					//console.log("浏览记录", res);
					this.historyList = res.data.records
					this.total = res.data.total * 1
					this.$forceUpdate()
				})
			},
			handleCurrentChange(val) {
				//console.log(`当前页: ${val}`);
				this.formData.page = val
				this.goodshistory()
			},
		}
	}
</script>

<style>
</style>
