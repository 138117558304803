<template>
	<div id="Collect">
		<div class="collectbox">
			<div class="collectbox-i" v-for="(item,index) in collectionList" :key="index" @click="dianji(item.id)">
				<el-image class="collectbox-i-t" :src="item.cover" fit="cover"></el-image>
				<div class="collectbox-i-c line-two">
					{{item.title}}
				</div>
				<div class="collectbox-i-b">
					¥{{item.price}}
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		goodscollectionList
	} from "../../utils/index.js"

	export default {
		name: "Collect",
		data() {
			return {
				formData: {
					page: 1,
					size: 12
				},
				collectionList: []
			}
		},
		created() {
			this.goodscollectionList()
		},
		methods: {
			dianji(id){
				this.$router.push({
					path:"shop_detail",
					query:{
						id:id
					}
				})
			},
			// 收藏列表
			goodscollectionList() {
				goodscollectionList(this.formData).then(res => {
					//console.log("收藏列表", res);
					this.collectionList = res.data.records
				})
			}
		}
	}
</script>

<style>
</style>
