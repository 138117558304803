<template>
	<div id="Order">
		<div class="salebox">
			<div class="salebox-tab flc">
				<div class="cursor" :class="typeIndex == index ? 'salebox-tab-a' : 'salebox-tab-i'"
					v-for="(item,index) in typeList" :key="index" @click="toggoleClick(index)">
					{{item}}
				</div>
			</div>
			<div class="saledetail flc">
				<div class="saledetail-l">
					商品信息
				</div>
				<div class="saledetail-c">
					状态
				</div>
				<div class="saledetail-r">
					操作
				</div>
			</div>

			<div class="salebox-list" v-for="(item,index) in orderArr" :key="index">
				<div class="salebox-list-t flc">
					<div class="salebox-list-t-l">
						{{item.updated.slice(0,10)}}
					</div>
					<div class="salebox-list-t-r">
						订单编号：{{item.number}}
					</div>
					<!-- 客服 -->
					<img src="../../assets/img/kefu.png" style="width: 20px;height: 20px;margin-left: 60px;"
						@click="clickkefu">
				</div>
				<div class="salebox-list-b flc">
					<div class="salebox-list-b-l flc">
						<div class="salebox-list-b-l-box">
							<div class="salebox-list-b-l-box-i flc" v-for="(item2,index2) in item.orderItemResponses"
								:key="index2">
								<el-image class="salebox-list-b-l-i" :src="item2.cover" fit="cover">
								</el-image>
								<div class="salebox-list-b-l-l">
									<div class="salebox-list-b-l-l-t line-two">
										{{item2.title}}
									</div>
									<div class="salebox-list-b-l-l-b flc">
										<div class="salebox-list-b-l-l-b-l">
											¥{{item2.price}}
										</div>
										<div class="salebox-list-b-l-l-b-r">
											×{{item2.num}}
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="salebox-list-b-l-r ">
							<div class="salebox-list-b-l-r-t">
								¥{{item.total}}
							</div>
							<div class="salebox-list-b-l-r-b">
								（含配送费¥{{item.postage}}）
							</div>
						</div>
					</div>

					<div class="salebox-list-b-c">
						<div class="salebox-list-b-c-t">
							{{statedList[item.stated]}}
						</div>
						<div class="salebox-list-b-c-t" v-if="item.stated==-1">
							已取消
						</div>
						<router-link class="salebox-list-b-c-b cursor"
							:to="{name:'OrderDetails',query:{cc:item.oid,cT:item.stated}}">
							订单详情
						</router-link>
					</div>
					<div class="salebox-list-b-b" v-if="item.stated !== 3">
						<router-link class="salebox-list-b-b-t" :to="{name: 'shop_page',params: {number: item.number}	}" v-if="item.stated == 0">
							立即支付
						</router-link>
						<div class="salebox-list-b-b-b" style="cursor: pointer;" @click="orderCloseOrder(item.oid)"
							v-if="item.stated == 0">
							取消订单
						</div>
					</div>
					<router-link class="salebox-list-b-b cursor"
						:to="{name:'comment_submit',query:{cc:item.oid,cid:item.orderItemResponses[0].orderItemId}}"
						v-if="item.stated == 3">
						<!-- <div class="salebox-list-b-b-t">
							评价
						</div>
						<div class="salebox-list-b-b-b">
							申请售后
						</div> -->
						评价
					</router-link>
				</div>
			</div>
			<!-- ="#D80000" -->
			<el-pagination class="salebox-pagination" background layout="prev, pager, next" :total="total"
				@current-change="handleCurrentChange">
			</el-pagination>
		</div>

		<kefu v-if="shows"></kefu>
	</div>
</template>

<script>
	import {
		orderlist,
		orderCloseOrder
	} from "../../utils/index.js"
	import kefu from "../common/kefu.vue"

	export default {
		name: "Order",
		components: {
			kefu
		},
		data() {
			return {
				typeList: ["全部订单", "待付款", "待发货", "待收货"],
				typeIndex: 0,
				formData: {
					page: 1,
					size: 10,
					stated: ""
				},
				orderArr: [],
				statedList: ["待付款", "待发货", "配送中", "待评论", "已完成", "退款"],
				total: 0,
				shows: false
			}
		},
		created() {
			this.orderlist()
		},
		methods: {
			// 订单列表
			orderlist() {
				// 0待支付1代发货2配送中3带评论4已完成5退款
				orderlist(this.formData).then(res => {
					//console.log("订单列表", res);
					this.orderArr = res.data.records
					this.total = res.data.total * 1
					this.$forceUpdate()
				})
			},
			handleCurrentChange(val) {
				//console.log(`当前页: ${val}`);
				this.formData.page = val
				this.orderlist()
			},
			toggoleClick(index) {
				this.typeIndex = index
				if (this.typeIndex == 0) {
					this.formData.stated = ""
				} else {
					this.formData.stated = index - 1
				}

				this.formData.page = 1
				this.orderlist()
			},
			orderCloseOrder(oid) {
				orderCloseOrder({
					oid: oid
				}).then(res => {
					//console.log("取消订单",res)
					if (res.code == 200) {
						window.location.reload()
					}
				})
			},
			clickkefu() {
				const routeData = this.$router.resolve({
					name: 'Chat'
				})
				window.open(routeData.href, '_blank')
			}
		},

	}
</script>

<style>
</style>
