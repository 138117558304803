<template>
	<div class="content">
    <div class="commentbox">
      <div class="commentbox-tab">
        <div class="cursor" :class="typeIndex == index ? 'commentbox-tab-a' : 'commentbox-tab-i'"
             v-for="(item,index) in tabList" :key="index" @click="toggleClick(index)">
          {{item}}
        </div>
      </div>
    </div>
    <div v-if="show">
      <div class="content-box" v-for="(item ) in list" :key="item.id" @click="clickitem(item)"  >
        <div class="content-box-top">
          <div class="texts" v-if="item.stated==0">
            处理中
          </div>
          <div class="texts" style="color:#999999;" v-else>
            已处理
          </div>

        </div>
        <div class="content-box-hear">
          <div style="display: flex;align-items: center">
            <el-image class="hearimg" v-if="item.headImg" :src="item.headImg" :fit="'cover'">
            </el-image>
            <div class="box-username">
              {{ item.username }}
            </div>
          </div>
          <div class="times">
            {{ item.created }}
          </div>
        </div>
        <div class="box-content">
          {{ item.content }}
        </div>
      </div>
    </div>



    <div class="box-content-box" v-else>
      <div style="margin: 20px;display: flex;justify-content: space-between">
        <div class="texts" v-if="listcontent.stated===0">
          处理中
        </div>
        <div class="texts" style="color:#999999;" v-else>
          已处理
        </div>

        <div class="box-back" @click=" show =true"> 返回 </div>
      </div>
      <div class="c-box">
        <div class="" style="display: flex;justify-content: space-between;align-items: center;margin-top: 20px;width: 940px;">
          <div class="" style="display: flex;align-items: center;">
            <el-image :src="listcontent.headImg||'../../static/images/tou.png'" style="width: 60px;height: 60px;border-radius: 50%;background-color: #E9D08E;" mode=""></el-image>
            <div class="text-name">
              {{listcontent.username}}
            </div>
          </div>

          <div style="width: 10px;"></div>
        </div>
        <div style="display: flex;width: 940px;justify-content: space-between; align-items: center">
          <div class="c-content1">
            {{listcontent.content}}
          </div>
          <div class="text-time" style="width: 260px;">
            {{listcontent.created}}
          </div>
        </div>
        <div class="d-back">
          <div class="d-b-text">
            回复信息：{{listcontent.feedbackContent || listcontent.reportFeedback}}
          </div>
          <div class="d-b-time">
            {{listcontent.updated}}
          </div>
        </div>
      </div>
      <div v-if="listcontent.title"  style=" margin-top: 20px;width: 940px;background: #FFFFFF;display: flex;flex-direction: column;align-items: center;">
        <div class="c-shangpin">
          <el-image :src="listcontent.cover" style="width: 200px;height: 150px;border-radius: 10px;" mode=""></el-image>
          <div style="display: flex;flex-direction: column;justify-content: space-between;margin-left: 20px;">
            <div class="c-s-text">
              {{listcontent.title}}
            </div>
            <div class="c-s-jia">
              ¥{{listcontent.goodsPrice}}
            </div>
          </div>
        </div>

      </div>
    </div>
	</div>
</template>

<script>
	import {
    consumerFeedbackList,
    consumerReportList,
  } from "../../utils/index"
  import fa from "element-ui/src/locale/lang/fa";
	
	export default{
    name: "Feedbacklist",
		data(){
			return{
				list :[],
        listcontent:'',
        typeIndex:0,
        show:true,
        tabList:["反馈","举报"]
			}
		},
		created() {
			this.consumerFeedbackList()
		},
		methods:{
        toggleClick(index) {
          this.typeIndex = index
          this.show=true

          if(index==0){
            this.list=[]
            this.consumerFeedbackList()
          }else{
            this.list=[]
            this.consumerReportList()
          }
        },
      consumerReportList(){
        consumerReportList().then(res=>{
          console.log("举报列表",res)
          this.list=res.data
        })
      },
			consumerFeedbackList(){
				consumerFeedbackList().then(res=>{
					//console.log("反馈列表",res)
					this.list=res.data
				})
			},
      clickitem(e){
        this.listcontent=e
        this.show=false
      }
		}
	}
</script>

<style lang="less" scoped>
	.content{
    min-height: 900px;
    .texts{
      font-size: 22px;
      font-weight: 500;
      color: #8D0000;
    }
    .content-box{
      width: 940px;
      margin: 0 30px;
      padding-bottom: 20px;
      border-bottom: 1px solid #E2E2E2;
      .content-box-top{
        display: flex;
        justify-content: space-between;

        .times{

        }
      }
      .content-box-hear{
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .hearimg{
          width: 60px;
          height: 60px;
          border-radius: 60px;
        }
        .box-username{
          font-size: 20px;
          margin-left: 15px;
        }
      }
      .box-content{
        margin-top: 20px;
        margin-left: 50px;
        font-size: 22px;
        width: 800px;
        padding: 5px;
        background: #EFEFEF;
      }

    }
    .box-back:hover{

      color: #8D0000;
    }
    .box-back{
      font-size: 22px;
      cursor: pointer;
    }
    .c-box{
      width: 940px;
      padding: 20px 30px;
      background: #FFFFFF;
      display: flex;
      flex-direction: column;
      align-items: center;

      .hangpin{
        width:880px ;
        padding: 20px 30px;
        background-color:#EFEFEF ;
        display: flex;
        .c-s-text{
          width: 300px;
          font-size: 30px;
          font-weight: bold;
          color: #333333;
          word-break: break-all;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
        .c-s-jia{
          font-size: 30px;
          font-weight: bold;
          color: #D80000;
        }
      }
      .d-back{
        width: 940px;
        padding: 20px;
        margin-top: 20px;
        background: #EFEFEF;
        display: flex;
        justify-content: space-between;
        .d-b-text{
          width: 800px;
          font-size: 22px;
          word-break: break-all;
          display: -webkit-box;
        }
        .d-b-time{
          font-size: 22px;
          font-weight: 500;
          color: #999999;
          text-align: right;
        }
      }
      .text-name{
        margin-left: 25px;
        font-size: 22px;
        font-weight: bold;
        color: #333333;
      }
      .text-time{
        font-size: 22px;
        font-weight: 500;
        color: #999999;
      }
      .c-content1{
        margin-top: 20px;
        margin-left: 50px;
        width: 370px;
        font-size: 22px;
        word-break: break-all;
        display: -webkit-box;
      }
      .c-content{
        margin-left: 80px;
        width: 500px;
        word-break: break-all;
        display: -webkit-box;
        // -webkit-line-clamp: 2;
        // -webkit-box-orient: vertical;
        // overflow: hidden;
      }
    }
    .c-shangpin{
     // width:940px ;
      background-color:#EFEFEF ;
      display: flex;
      .c-s-text{
        width: 300px;
        font-size: 30px;
        font-weight: bold;
        color: #333333;
        word-break: break-all;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
      .c-s-jia{
        margin-left: 20px;
        font-size: 30px;
        font-weight: bold;
        color: #D80000;
      }
    }
  }
</style>