<template>
	<div id="news">
		<div class="commentbox">
			<div class="commentbox-tab">
				<div class="cursor" :class="typeIndex == index ? 'commentbox-tab-a' : 'commentbox-tab-i'"
					v-for="(item,index) in tabList" :key="index" @click="toggleClick(index)">
					{{item}}
				</div>
			</div>
		</div>
		<div class="newsbox" v-if="typeIndex==0">
			<div class="sbox" v-for="(items,index) in NoticeList" :key="items.id">
				<div class="sbox-top">
					<div>
						{{items.title}}
					</div>
					<div>
						{{items.createtime}}
					</div>
				</div>
				<div class="sbox-content" v-html="items.content" ></div>
				
			</div>
		</div>
		<div class="newsbox"  v-if="typeIndex==1">
			<div class="sbox" v-for="(items,index) in SysNoticeList" :key="items.id">
				<div class="sbox-top">
					<div>
						{{items.title}}
					</div>
					<div>
						{{items.createdTime}}
					</div>
				</div>
				<div  class="sbox-content" v-html="items.content" ></div>
				
			</div>
		</div>
	</div>
</template>

<script>
	import {
		imNoticeList,
		imSysNoticeList,
	} from "../../utils/index.js"
	
	export default {
		name: "News",
		components: {
			
		},
		data() {
			return {
				tabList: ["消息通知", "系统公告"],
				typeIndex:0,
				NoticeList:[],//通知
				SysNoticeList:[],//系统通知
			}
		},
		created() {
			this.imNoticeList()
			this.imSysNoticeList()
		},
		watch: {
			
		},
		methods: {
			toggleClick(index) {
				this.typeIndex = index
			// 	if (this.typeIndex == 0) {
			// 		this.formData.stated = 3
			// 	} else {
			// 		this.formData.stated = 4
			// 	}
			
			// 	this.formData.page = 1
			// 	this.orderlist()
			},
			imNoticeList(){
				imNoticeList().then(res=>{
					//console.log("通知列表",res)
					this.NoticeList=res.data
				})
			},
			imSysNoticeList(){
				imSysNoticeList({
					type:1
				}).then(res=>{
					//console.log("系统通知",res)
					this.SysNoticeList=res.data
				})
			}
		}
	}
</script>

<style lang="less">
	#news{
		min-height: 900px;
		.newsbox{
			padding: 20px;
			.sbox{
				padding: 20px 40px;
				border-bottom: 1px solid #E2E2E2;
				font-size: 20px;
				.sbox-top{
					display: flex;
					justify-content: space-between;
				}
				.sbox-content{
					margin-top: 30px;
				}
			}
		}
	}
	
</style>